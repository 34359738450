import React from "react";
import './partnerherosection.css'
import BookDemoButton from '../../BookDemoButton'

const PartnerHeroSection = ()=>{
    return(
      <>
        {/* <!-- Hero --> */}
      <section className="dark-mode position-relative jarallax pb-xl-3" data-jarallax data-speed="0.4">
        {/* <!-- Parallax img --> */}
        <div className="jarallax-img bg-dark w-100 h-100 opacity-60" style={{ backgroundImage: 'url(assets/custom_img/partners_img.jpg)' }}></div>
        {/* <!-- Overlay bg --> */}
        <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50 zindex-1"></span>
        {/* <!-- Overlay content --> */}
        <div className="container position-relative pb-5 zindex-5">
          {/* <!-- Hero Text --> */}
          <div className="row mb-xxl-5 py-md-4 py-lg-5 padding-custom">
            <div className="col-lg-6 col-md-6 pb-3 pb-md-0 mb-4 mb-md-5">
              <div className="mb-3 fs-md text-light">
              Service Partners
              </div>
              <h1 className="display-6 pb-md-3 text-uppercase ">Explore our directory of Orbix Certified Partners</h1>
              <div className="d-flex flex-wrap mb-md-2 mb-4 pb-md-2 text-white">
               <p>Service Partners trained to use the Orbix Platform offering enhanced NDE Services.</p>
               <p>Interested in joining or learning more about how the Orbix Platform can enhance your operations? Contact us today.</p>
             
              </div>
              <BookDemoButton/>
            </div>
            {/* <!-- Orbix Badge --> */}
            <div className="d-flex justify-content-end image-position-custom-md col-lg-6 col-md-6 pb-3 pb-md-0 mb-4 mb-md-5">
                <img src="assets/custom_img/orbix-badge.svg"  alt="orbix-badge" className="orbix-badge" />
            </div>

          </div> 
        </div>
      </section>
      </>
    )
};

export default PartnerHeroSection;