import React, { Fragment } from 'react';
import './home.component.css';
import HeaderSection from '../../components/HeaderSection/headersection.component';
import HeroSection from '../../components/HeroSection/herosection.componenet';
import WhySection from '../../components/WhySection/whysection.componenet';
import OrbixDiagram from '../../components/DiagramSection/diagram.component';
import ItemsSection from '../../components/ItemsSection/itemssection.component';
import PlatformSection from '../../components/PlatformSection/platformsection.component';
import InteroperabilitySection from '../../components/InteroperabilitySection/interoperabilitysection';
import OrbixContact from '../../components/ContactSection/contact.component';
// import OrbixCalculator from '../../components/CalculatorSection/calculator.component';
import FooterSection from '../../components/FooterSection/footersection.component';



function Home() {

  return (
    <Fragment>
      {/* Main */}
      <main className="page-wrapper">
        {/* Header */}
        <HeaderSection />
        
        {/* Hero */}
        <HeroSection />
        
        {/* Why Section */}
        <WhySection />
        
        {/* Orbix Diagram */}
        <OrbixDiagram />
        
        {/* Items Section */}
        <ItemsSection />
          
        {/* Platform Section */}
        <PlatformSection />
        
        {/* Interoperability Section */}
        <InteroperabilitySection />
        
        {/* Calculator */}
         {/* <OrbixCalculator />  */}
        
        {/* Contact */}
        <OrbixContact />
      </main>
      
      {/* Footer */}
      <FooterSection />
      
    </Fragment>
  );
  
}

export default Home;
