import React from "react";

const PlatformSection = () =>{
    return(
        <>
        <section className="container pt-md-5 mt-md-5  pt-4 pb-2 mt-4">
        <h2 className="h1 text-center pb-3 pb-lg-4">One Platform — Endless Value</h2>
        {/* <!-- Nav tabs --> */}
        <ul className="nav nav-tabs flex-nowrap justify-content-lg-center overflow-auto pb-2 mb-3 mb-lg-4" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link text-nowrap active" id="project-management-tab" data-bs-toggle="tab" data-bs-target="#project-management" type="button" role="tab" aria-controls="project-management" aria-selected="true">
            Real Time Oversight 
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-nowrap" id="remote-work-tab" data-bs-toggle="tab" data-bs-target="#remote-work" type="button" role="tab" aria-controls="remote-work" aria-selected="false">   
            Digital Workflows
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-nowrap" id="product-release-tab" data-bs-toggle="tab" data-bs-target="#product-release" type="button" role="tab" aria-controls="product-release" aria-selected="false">
            Hardware Interoperability
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link text-nowrap" id="campaign-planning-tab" data-bs-toggle="tab" data-bs-target="#campaign-planning" type="button" role="tab" aria-controls="campaign-planning" aria-selected="false">
            Enterprise Security
            </button>
          </li>
        </ul>
        {/* <!-- Tab panes --> */}
        <div className="tab-content bg-secondary rounded-3 py-4">
          {/*  Real Time Oversight  */}
          <div className="tab-pane fade show active" id="project-management" role="tabpanel" aria-labelledby="project-management-tab">
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h3 className="mb-lg-4">Real Time Oversight and Management</h3>
                <p> Orbix allows you to easily manage all your your NDE across modalities, projects, companies, and subcontractors from one platform. <br/><br/>
                Inspection problems come at a significant financial cost. Orbix enables unparalleled oversight  into your  inspection data and processes, allowing you to quickly catch problems before they occur.
                </p>
              </div>
              <div className="col-lg-6 col-md-7 mt-md-4">
                <img src="assets/img/xpose/graphic_1.png" className="d-block my-lg-2 mx-auto me-md-0" width="400" alt="graph"/>
              </div>
            </div>
          </div>
        {/* Digital Workflows*/}
          <div className="tab-pane fade" id="remote-work" role="tabpanel" aria-labelledby="remote-work-tab">
            <div className="row align-items-center pt-3 pb-2 pb-sm-3 pt-sm-4 pt-md-0 px-3 px-sm-4 px-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h3 className="mb-lg-4">Efficient Digital Workflows</h3>
                <p>
                Modern analogue workflows are inefficient, unreliable, and insecure. Today NDE is often reliant on slow and error-prone manual workflows. Data is stored in thumb drives, boxes, and disorganized cloud drive  repositories.
                  <br/><br/>
                  Orbix enables inspectors to  quickly view and interpret the inspection data without leaving the browser with the support of computer vision AI systems.  Inspectors can verify the calls of field technicians and leave detailed feedback that includes measurements and reports in a manner that is transparent and easy to track by managers and end users.
              </p>
              </div>
              <div className="col-lg-6 col-md-7 mt-md-4">
                <img src="assets/img/xpose/graphic_2.png" className="d-block my-lg-2 mx-auto me-md-0" width="502" alt="graph"/>
              </div>
            </div>
          </div>
          {/* Hardware Interoperability*/}
          <div className="tab-pane fade" id="product-release" role="tabpanel" aria-labelledby="product-release-tab">
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 pe-sm-4 pe-md-0 ps-sm-4 ps-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h3 className="mb-lg-4">Maximum Interoperability</h3>
                <p>
                Orbix is fully compatible with a diverse range of hardware and is designed to support all imaging methods and technologies that comply with the ASTM E2339 standard.
                    <br/><br/>
                    Our advanced data architecture transcends conventional workflows, offering unparalleled flexibility to integrate with various data standards. This capability ensures that Orbix delivers a suite of functionalities that goes beyond what is commonly found in standard imaging software solutions.
                 
                </p>
              </div>
              <div className="col-lg-6 col-md-7 mt-n3 mt-md-1">
                <img src="assets/img/xpose/graphic_3.png" className="d-block mb-lg-2 mx-auto me-md-0" width="525" alt="graph"/>
              </div>
            </div>
          </div>
          {/* Enterprise Security */}
          <div className="tab-pane fade" id="campaign-planning" role="tabpanel" aria-labelledby="campaign-planning-tab">
            <div className="row align-items-center pt-3 pt-sm-4 pt-md-0 px-3 pe-sm-4 pe-md-0 ps-sm-4 ps-lg-0">
              <div className="col-lg-4 col-md-5 offset-lg-1 text-center text-md-start">
                <h3 className="mb-lg-4">Data Security and Sovereignty</h3>
                <p>
                    Data security and data sovereignty refers to both the privacy of your data and your  control of your data respectively. 
                    <br/><br/>
                    The Orbix platform provides complete privacy and control of your data. When using Orbix it is impossible for even our development team to access your data without explicit permission.We achieve this high level of security by providing  each client with their own tenant  and a completely isolated database. 
                    <br/><br/>
                    Custom deployments on user managed on-premises  or cloud servers are available on request.
                </p>
              </div>
              <div className="col-lg-6 col-md-7 mt-n3 mt-md-1">
                <img src="assets/img/xpose/graphic_4.png" className="d-block mb-lg-2 mx-auto me-md-0" width="545" alt="graph"/>
              </div>
            </div>
          </div>
        </div>
      </section>

        </>
    )
}

export default PlatformSection;