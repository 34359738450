import React from "react";

const IntelligentDataManagement =() =>{
    return(
        <>
           <section className="container text-center pt-5 px-3 ">
          <h2 className="h1 pt-5 mb-4">Intelligent Data Management</h2>
          <div className="row justify-content-center mb-md-2 mb-lg-5">
            <div className="col-md-10">
              <p className="fs-lg mb-lg-0">Orbix's Intelligent Data Management system creates a secure, centralized hub for all your inspection data, workflows, and communications. By offering advanced storage, comprehensive tracking, and intuitive controls, Orbix elevates your data management to a level that traditional methods simply can't match.</p>
            </div>
          </div>

        <div className="row align-items-start justify-content-center">
          <div className=" col-lg-5 mb-5">
            <img src="assets/features_img/intelligent_data_management.png" class="d-block mx-auto" width="746" alt="Image"/>
          </div>
          <div className=" col-lg-7 d-flex d-sm-block flex-column">
           
          <ul className="list-unstyled ">
              <li className="d-flex align-items-start pb-1 mb-2">
              <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                    <img src="assets/features_img/features_icons/multi_project_management.svg" alt="Icon"/>
                  </div>
               <p className="text-start ps-4"><span className="fw-bold text-white">Multi-Project Management :</span> Seamlessly manage data across multiple projects from a single platform. Orbix organizes your NDE operations, making it easy to track and access information for any project, anytime.</p>
              </li>
              <li className="d-flex align-items-start pb-1 mb-2">
              <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
              <img src="assets/features_img/features_icons/secure_data_storage.svg" alt="Icon"/>
                  </div>
               <p className="text-start ps-4"><span className="fw-bold text-white">Secure Data Storage  :</span> Your radiographic testing data is safeguarded in a specialized DICONDE Server, ensuring the highest level of security and accessibility for your most critical information.</p>
              </li>
              <li className="d-flex align-items-start pb-1 mb-2">
              <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
              <img src="assets/features_img/features_icons/data_capture.svg" alt="Icon"/>
                  </div>
               <p className="text-start ps-4"><span className="fw-bold text-white">Comprehensive Data Capture  :</span>  Orbix doesn't just store your data – it captures everything. From inspection reports and certifications to user information and defect measurements, every aspect of your NDE process is recorded and easily accessible.</p>
              </li>
              <li className="d-flex align-items-start pb-1 mb-2">
              <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
              <img src="assets/features_img/features_icons/complete_traceability.svg" alt="Icon"/>
                  </div>
               <p className="text-start ps-4"><span className="fw-bold text-white">Complete Traceability :</span> Every decision, every communication, every action in the inspection process is logged. This creates a clear, traceable history for each inspection, enhancing accountability and providing valuable insights.</p>
              </li>
              <li className="d-flex align-items-start pb-1 mb-2">
              <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
              <img src="assets/features_img/features_icons/data_control.svg" alt="Icon"/>
                  </div>
               <p className="text-start ps-4"><span className="fw-bold text-white">Data Control and Portability  :</span> Take full control of your data. With Orbix, you can download all your information locally or migrate it to your own server with just a click. Your data remains your asset, always within reach and under your control.</p>
              </li>
   
            </ul>
          
          </div>
        </div>
    
          
        </section>

        </>
    )

}

export default IntelligentDataManagement;