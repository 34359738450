import React from 'react';

import HeaderSection from '../../components/HeaderSection/headersection.component';
import NewsInnerSection from '../../components/NewsInnerSection/newsinnersection.component';
import OrbixContact from '../../components/ContactSection/contact.component';
import FooterSection from '../../components/FooterSection/footersection.component';



import { Fragment } from "react";


function NewsInner() {
  return (
    <Fragment>
    {/* main */}
    <main className="page-wrapper">
    {/* Header  */}
    <HeaderSection />

    {/* Hero  */}
   

    {/* Partners Section  */}
    <NewsInnerSection/>

    {/* Contact -1 */}
    <OrbixContact/>
     
    </main>
    {/* Footer  */}
    <FooterSection />
     

    </Fragment>
  )
}

export default NewsInner