import React from "react";

const WhySection =() =>{
    return(
        <>
        <section className="position-relative py-5">
        <div className="container position-relative zindex-5 pb-md-4 pt-md-2 pt-lg-3 pb-lg-5">
          <div className="row justify-content-center text-center pb-3 mb-sm-2 mb-lg-3">
            <div className="col-xl-10 col-lg-7 col-md-9">
              <h2 className="h1 mb-lg-4">Why Partner with Orbix?</h2>
              <p className="fs-lg text-muted mb-0">Ensure  the reliability and efficiency of your Nondestructive Evaluations, protect the value of your  projects, save valuable man hours.</p>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-0 pb-xl-3">
            {/* <!-- Item --> */}
            <div className="col position-relative">
              <div className="card border-0 bg-transparent rounded-0 p-md-1 p-xl-3">
                <div className="d-table bg-secondary rounded-3 p-3 mx-auto mt-3 mt-md-4">
                  <img src="assets/img/landing/saas-2/features/notifications.svg" width="40" alt="Comments"/>
                </div>
                <div className="card-body text-center">
                  <h3 className="h5 pb-1 mb-2">AI-Assisted Quality Control</h3>
                  <p className="mb-0">Harness the power of computer vision and AI and ensure the integrity oif of your nondestructive evaluations.</p>
                </div>
              </div>
              <hr className="position-absolute top-0 end-0 w-1 h-100 d-none d-sm-block"/>
              <hr className="position-absolute top-100 start-0 w-100 d-none d-sm-block"/>
            </div>
            {/* <!-- Item --> */}
            <div className="col position-relative">
              <div className="card border-0 bg-transparent rounded-0 p-md-1 p-xl-3">
                <div className="d-table bg-secondary rounded-3 p-3 mx-auto mt-3 mt-md-4">
                  <img src="assets/img/xpose/statistics.svg" width="40" alt="Analytics"/>
                </div>
                <div className="card-body text-center">
                  <h3 className="h5 pb-1 mb-2">Optimize Your Operations</h3>
                  <p className="mb-0">    Increase productivity of your skilled NDE personnel and improve communication and collaboration among stakeholders.</p>
                </div>
              </div>
              <hr className="position-absolute top-0 end-0 w-1 h-100 d-none d-md-block"/>
              <hr className="position-absolute top-100 start-0 w-100 d-none d-sm-block"/>
            </div>
            {/* <!-- Item --> */}
            <div className="col position-relative">
              <div className="card border-0 bg-transparent rounded-0 p-md-1 p-xl-3">
                <div className="d-table bg-secondary rounded-3 p-3 mx-auto mt-3 mt-md-4">
                  <img src="assets/img/landing/saas-2/features/analytics.svg" width="40" alt="Group"/>
                </div>
                <div className="card-body text-center">
                  <h3 className="h5 pb-1 mb-2">Realtime Oversight</h3>
                  <p className="mb-0">Complete transparency and oversight into all of your NDE inspection, reporting and compliance among all locations, projects, and companies.</p>
                </div>
              </div>
              <hr className="position-absolute top-0 end-0 w-1 h-100 d-none d-sm-block d-md-none"/>
              <hr className="position-absolute top-100 start-0 w-100 d-none d-sm-block"/>
            </div>
            {/* <!-- Item --> */}
            <div className="col position-relative">
              <div className="card border-0 bg-transparent rounded-0 p-md-1 p-xl-3">
                <div className="d-table bg-secondary rounded-3 p-3 mx-auto mt-3 mt-md-4">
                  <img src="assets/img/xpose/team.svg" width="40" alt="Notifications"/>
                </div>
                <div className="card-body text-center">
                  <h3 className="h5 pb-1 mb-2">End-to-End Digital Workflows</h3>
                  <p className="mb-0">Modernize your NDE operations, transform your workflows, and manage all your NDE data and  documents from one platform.</p>
                </div>
              </div>
              <hr className="position-absolute top-0 end-0 w-1 h-100 d-none d-md-block"/>
              <hr className="position-absolute top-100 start-0 w-100 d-none d-sm-block d-md-none"/>
            </div>
            {/* <!-- Item --> */}
            <div className="col position-relative">
              <div className="card border-0 bg-transparent rounded-0 p-md-1 p-xl-3">
                <div className="d-table bg-secondary rounded-3 p-3 mx-auto mt-3 mt-md-4">
                  <img src="assets/img/landing/saas-2/features/tasks.svg" width="40" alt="Tasks"/>
                </div>
                <div className="card-body text-center">
                  <h3 className="h5 pb-1 mb-2">Ensure Compliance</h3>
                  <p className="mb-0">View NDE inspection data and all relevant compliance documents with one click. All component data and documentation saved for lifetime of the asset.</p>
                </div>
              </div>
              <hr className="position-absolute top-0 end-0 w-1 h-100 d-none d-sm-block"/>
            </div>
            {/* <!-- Item --> */}
            <div className="col position-relative">
              <div className="card border-0 bg-transparent rounded-0 p-md-1 p-xl-3">
                <div className="d-table bg-secondary rounded-3 p-3 mx-auto mt-3 mt-md-4">
                  <img src="assets/img/landing/saas-2/features/security.svg" width="40" alt="Security"/>
                </div>
                <div className="card-body text-center">
                  <h3 className="h5 pb-1 mb-2">Data Security</h3>
                  <p className="mb-0">Total security and sovereignty of your data. Enterprise security, multi-tenant architecture with completely isolated databases.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="position-absolute top-0 start-0 w-100 h-100" style={{ "backgroundColor": "rgba(255,255,255,.05"}}></div>
      </section>

        </>
    )

}

export default WhySection