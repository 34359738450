import React from 'react';
import HeaderSection from '../../components/HeaderSection/headersection.component';
import PartnerHeroSection from '../../components/PartnersHeroSection/partnersherosection';
import PartnersSection from '../../components/PartnerSection/partnerssection.component';
import OrbixContact from '../../components/ContactSection/contact.component';
import FooterSection from '../../components/FooterSection/footersection.component';
import { Fragment } from "react";


function Partners() {
  return (
    <Fragment>
    {/* main */}
    <main className="page-wrapper">
    {/* Header  */}
    <HeaderSection />

    {/* Hero  */}
    <PartnerHeroSection/>

    {/* Partners Section  */}
    <PartnersSection/>

    {/* Contact -1 */}
    <OrbixContact/>
     
    </main>
    {/* Footer  */}
    <FooterSection />

    </Fragment>
  )
}

export default Partners