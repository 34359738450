import React from "react";

const InteroperabilitySection = ()=>{
    return(
        <>
        <section class="container text-center mt-3 pb-3 ">
          <h2 class="h1 pt-5 mb-4">Maximum Interoperability</h2>
          <div class="row justify-content-center mb-md-2 mb-lg-5">
            <div class="col-lg-10 col-md-8">
              <p class="fs-lg text-muted mb-lg-0">One solution to digitize all your NDE operations. Orbix is completely hardware agnostic.  Our Hybrid-DICONDE architecture provides maximum interoperability without sacrificing data integrity.</p>
            </div>
          </div>
          <div class="position-relative rounded-3 overflow-hidden mb-5 pb-3">
            <span class="position-absolute top-0 start-0 w-100 h-100 "></span>
            <img src="assets/img/xpose/ArchitectureDiagram.svg " alt="Cover"/>
          </div>
        </section>
  
        </>
    )
}

export default InteroperabilitySection; 