import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './newssection.component.css';

const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');
};

const NewsSection = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const postsPerPage = 10; // Set how many posts you want per page

    useEffect(() => {
      const fetchPosts = async () => {
          // Replace 'newsCategoryId' with the actual ID of your "News" category
          const newsCategoryId = 'news'; 
          const response = await fetch(`https://public-api.wordpress.com/rest/v1.1/sites/orbix0.wordpress.com/posts/?page=${currentPage}&number=${postsPerPage}&category=${newsCategoryId}`);
          const data = await response.json();
          setPosts(data.posts);
      };
  
      fetchPosts();
  }, [currentPage]);
  


    const filteredPosts = searchQuery
    ? posts.filter((post) =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : posts;

   // Update the current page
   const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    };
    
    return(
        <>
         {/* <!-- Breadcrumb --> */}
      <nav className="container mt-lg-4 pt-5" aria-label="breadcrumb">
        <ol className="breadcrumb mb-0 pt-5">
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">News</li>
        </ol>
      </nav>
        <section className="container ">
            <div className="row  gy-3 mb-4 pb-lg-3 pb-1">
                <div className="col-lg-5 col-md-4 ">
                    <h1 className="mb-2 mb-md-0">News</h1>
                </div>
                <div className="col-lg-4 col-md-8 ms-auto">
                    <form className=" gy-2">
                    
                        <div className="input-group">
                        <input
                          type="text"
                          className="form-control rounded"
                          placeholder="Search news..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />

                        <i className="bx bx-search position-absolute top-50 end-0 translate-middle-y me-3 zindex-5 fs-lg"></i>
                        </div>
                    
                    </form>
                </div>
            </div>
            <div className="row row-cols-lg-3 row-cols-sm-2 row-cols-1 gy-md-4 gy-2">
            {filteredPosts.map((post) => (
                    <div className="col pb-3" key={post.ID}>
                        <article className="card border-0 shadow-sm h-100">
                            <div className="position-relative">
                            <Link to={`/news/${titleToSlug(post.title)}`}  className="position-absolute top-0 start-0 w-100 h-100" aria-label="Read more"></Link>
                                {/* Use the post featured image if available */}
                                <img src={post.featured_image}  className="card-img-top" alt={post.title} />
                            </div>
                            <div className="card-body pb-4">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <span className="fs-sm text-muted">{new Date(post.date).toLocaleDateString()}</span>
                              </div>
                                <h3 className="h5 mb-0">
                                <Link to={`/news/${titleToSlug(post.title)}`}>{post.title}</Link>
                                </h3>
                            </div>
                            <div className="card-footer">
                            <div dangerouslySetInnerHTML={{ __html: `${post.excerpt.replace(/<[^>]*>?/gm, '').substring(0, 180)}...` }} />
                            <Link to={`/news/${titleToSlug(post.title)}`} className="btn btn-link px-0">
                                    Read more
                                    <i className="bx bx-right-arrow-alt fs-xl ms-2"></i>
                            </Link>
                            </div>
                        </article>
                    </div>
                ))}
            </div>

            {/* Pagination controls */}
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center pt-md-4 pt-2">
                    <li className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            <i className="bx bx-chevron-left mx-n1"></i>
                        </button>
                    </li>
                    {/* Dynamically generate page numbers based on your API's pagination data */}
                    {/* Placeholder for pagination - Implement as needed */}
                    <li className="page-item">
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            <i className="bx bx-chevron-right mx-n1"></i>
                        </button>
                    </li>
                </ul>
            </nav>
        </section>
        </>
    )
};

export default NewsSection