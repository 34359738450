import React from 'react';
import { Link } from "react-router-dom"; 

import HeaderSection from '../../components/HeaderSection/headersection.component';
import { Fragment } from 'react';

function ThankYouPage() {
  return (
    <Fragment>
      {/* Main */}
      <main className="page-wrapper d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      {/* Header  */}
    <HeaderSection />
        {/* Thank you page information */}
        <div className="text-center">
        <i className="bx bx-check-circle text-success me-2" style={{ fontSize: '4rem' }}></i>
          <h2 className="mt-3">Thank You</h2>
          <p>
            We've noted your interest in Orbix. Our team will be in touch soon with more details.  <br /> We're excited about the possibility of working together!
            <br />
            Warm regards,
            <br />
            Orbix Team
          </p>
          <Link to="/" className="btn btn-primary btn-sm fs-sm rounded">
          &nbsp;Back to Home
          </Link>
        </div>
        
      </main>
  
    </Fragment>
  );
}

export default ThankYouPage;
