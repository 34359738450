import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const OrbixContact = ()=> {
  // const navigate = useNavigate();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Your form submission logic here
  //   // After successfully submitting the form, navigate to the Thank You page
  //   navigate('/thank-you');
  // };
  return (
    <>
    <section className="container mt-md-4 mt-3 pb-5  mb-md-4 mb-lg-1" id='contact'>
        <div className=" bg-secondary position-relative  rounded-3 py-5 mb-2">
          <div className="row align-items-center  pb-2 py-md-3 py-lg-5 px-4 px-lg-0 position-relative zindex-3">
            <div className="col-xl-3 col-lg-4 col-md-5 offset-lg-1">
              <p className="lead mb-2 mb-md-3">Interested in joining? </p>
              <h2 className="h1 pb-3">Don’t hesitate to contact us to learn more.</h2>
            </div>
            {/* <form className="col-lg-6 col-md-7 offset-xl-1 needs-validation" noValidate> */}
            <form className="col-lg-6 col-md-7 offset-xl-1" action="https://formkeep.com/f/85f9d0311fa7" acceptCharset="UTF-8" encType="multipart/form-data" method="POST" target="_self">
              <div className="row">
                <div className="col-sm-6 mb-4">
                  <label htmlFor="name" className="form-label fs-base">Full name</label>
                  <input type="text" id="name" name="name" className="form-control form-control-lg form-control-text-align " required/>
                  <div className="invalid-feedback">Please enter your name!</div>
                </div>
                <div className="col-sm-6 mb-4">
                  <label htmlFor="email" className="form-label fs-base">Email address</label>
                  <input type="email" id="email" name="email" className="form-control form-control-lg form-control-text-align " required/>
                  <div className="invalid-feedback">Please provide a valid email address!</div>
                </div>
                <div className="col-12 pb-2 mb-4">
                  <label htmlFor="message" className="form-label fs-base">Message</label>
                  <textarea id="message" name="message" className="form-control form-control-lg " rows="4" required></textarea>
                  <div className="invalid-feedback">Please enter your message!</div>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-md">Submit message</button>
            </form>
          </div>
        </div>
    </section>
    </>
  )
};
export default OrbixContact;