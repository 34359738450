import React from "react";
import { NavLink, Link} from "react-router-dom";

import './headersection.component.css'


const HeaderSection = () =>{
    return(
        <>
         <header className="header navbar navbar-expand-lg navbar-light position-absolute navbar-sticky navbar-stuck">
          <div className="container  px-3 pt-2 ">
          <NavLink to="/" className="navbar-brand pe-3">
            <img src={`${process.env.PUBLIC_URL}/assets/img/logo_large 1.png`} width="150" alt="orbix" />

          </NavLink>
            <button type="button" className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
            </button>
            <div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
              <div className="offcanvas-header border-bottom border-light">
                <h5 className="offcanvas-title text-white">Menu</h5>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body  justify-content-center">
                <ul className="navbar-nav mb-2 mb-lg-0 no-desktop" style={{ flexDirection: 'column' }}>
                  <li className="nav-item mb-3">
                    <NavLink to="/features"  className="nav-link d-lg-inline-flex me-3" activeClassName="active">
                    &nbsp;Features
                  </NavLink>
                  </li>
                  <li className="nav-item mb-3">
                  <NavLink to="/partners"  className="nav-link d-lg-inline-flex" activeClassName="active">
                      &nbsp;Partners
                    </NavLink>
                  </li>
                 
                  <li className="nav-item mb-3">
                    <NavLink to="/news"  className="nav-link d-lg-inline-flex" activeClassName="active">
                        &nbsp;News
                    </NavLink>
                  
                  </li>
                  <li className="nav-item">
                    <a href="https://demo.orbixnde.com" target="__blank" className="btn btn-primary btn-sm fs-sm rounded d-lg-inline-flex">
                      &nbsp;Sign In
                    </a>
                  </li>
                </ul>
              </div>
            </div>
                <NavLink to="/features"  className="nav-link d-none d-lg-inline-flex me-3" activeClassName="active">
                  &nbsp;Features
                </NavLink>
                <NavLink to="/partners"  className="nav-link d-none d-lg-inline-flex me-3" activeClassName="active">
                  &nbsp;Partners
                </NavLink>
                <NavLink to="/news"  className="nav-link d-none d-lg-inline-flex me-3" activeClassName="active">
                  &nbsp;News
                </NavLink>
                <a href="https://demo.orbixnde.com" target="__blank" className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex">
                  &nbsp;Sign In
                </a>
                
          </div>
      </header>

        </>
    )
}

export default HeaderSection;