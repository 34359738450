import React, { useEffect, useRef } from 'react';

const BookDemoButton = () => {
  const anchorRef = useRef(null);
  const scriptLoaded = useRef(false);
  const buttonLoaded = useRef(false); // New flag to prevent multiple button creations

  useEffect(() => {
    const loadButton = () => {
      console.log('Attempting to load button');
      if (
        window.calendar &&
        window.calendar.schedulingButton &&
        anchorRef.current &&
        !buttonLoaded.current // Check if button is already loaded
      ) {
        console.log('Loading button');
        window.calendar.schedulingButton.load({
          url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ25PYcnS8Dc8J-Cy4G0D4xgy-aD6LlI0LZzd3HMmVjcWmuhRADwCLxj7M8g2KxvGmZZ3ukiPl4q?gv=true',
          color: '#6366f1',
          label: 'Book a Demo',
          id: 'bookaDemo',
          target: anchorRef.current,
        });
        buttonLoaded.current = true; // Set flag to true after loading button
      }
    };

    if (!scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js'; // Ensure this path is correct
      script.onload = () => {
        scriptLoaded.current = true;
        loadButton();
      };
      document.body.appendChild(script);
    } else {
      loadButton();
    }

    // Cleanup function to reset the flag
    return () => {
      if (scriptLoaded.current && anchorRef.current) {
        anchorRef.current.innerHTML = '';
        buttonLoaded.current = false; // Reset flag on component unmount
      }
    };
  }, []);

  return <div ref={anchorRef}></div>;
};

export default BookDemoButton;
