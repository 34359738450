import React from 'react';
import HeaderSection from '../../components/HeaderSection/headersection.component';
import NewsSection from '../../components/NewsSection/newssection.component';
import OrbixContact from '../../components/ContactSection/contact.component';
import FooterSection from '../../components/FooterSection/footersection.component';

import { Fragment } from "react";
function News() {
  return (
    <Fragment>
    {/* main */}
    <main className="page-wrapper">
    {/* Header  */}
    <HeaderSection />

    {/* Hero  */}
   

    {/* Partners Section  */}
    <NewsSection/>

    {/* Contact -1 */}
    <OrbixContact/>
     
    </main>
    {/* Footer  */}
    <FooterSection />

    </Fragment>
  )
}

export default News