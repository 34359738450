import React from "react";
import './DataControlHub.css'

const DataControlHubSection =() =>{
    return(
        <>
          
        <section className="container  pt-3 mb-1">
        <div className="row justify-content-center pb-xl-3">

          <div className="col-lg-10 ">
            <h2 className="h1 text-center text-md-center mb-4">Enterprise Security and Data Control</h2>
            <p className="fs-lg text-center text-md-center mb-4 mb-xl-5">Orbix sets the gold standard for NDE data security. Our multi-layered approach ensures your sensitive information remains protected, accessible, and under your control at all times. Whether you're a small team or a global enterprise, Orbix adapts to your security needs without compromising on performance or ease of use.</p>
            <div className="row row-cols-1 row-cols-md-2 pt-2 pt-sm-3 pt-xl-2">

             
              <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                  <img src="assets/features_img/features_icons/data_security.svg" alt="Icon"/>
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Enterprise-Level Data Security</h3>
                    <p className="mb-0">Your data remains yours alone. Orbix ensures full data isolation for every client at the database and application level, safeguarding privacy and security through encryption, access controls, and compliance with key standards.</p>
                  </div>
                </div>
              </div>

        
              <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                  <img src="assets/features_img/features_icons/data_storage.svg" alt="Icon"/>
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Robust Data Storage</h3>
                    <p className="mb-0">Move beyond basic DICONDE drives. Orbix’s innovative data architecture takes NDE data management to the next level. Our platform integrates full DICONDE PACS functionality with modern, high-performance capabilities. Each client benefits from a dedicated DICONDE server, providing unparalleled security, scalability, and accessibility.</p>
                  </div>
                </div>
              </div>

             
              <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                  <img src="assets/features_img/features_icons/data_protection.svg" alt="Icon"/>
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Always-On Data Protection</h3>
                    <p className="mb-0"> With Orbix, your data is always safe and accessible. Our platform continuously backs up your data and securely replicates it across multiple geographic locations, ensuring uninterrupted access and peace of mind. Even in the face of unexpected disruptions, you can count on Orbix to keep your data secure and services running smoothly.</p>
                  </div>
                </div>
              </div>

         
              <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                <div className="d-flex align-items-start ps-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                  <img src="assets/features_img/features_icons/flexible_deployment.svg" alt="Icon"/>
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Flexible Deployment Options</h3>
                    <p className="mb-0">Choose the setup that works best for your organization’s security requirements. Orbix can be hosted on our secure servers or deployed on-premises, giving you full control over your data environment.</p>
                  </div>
                </div>
              </div>

              <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                <div className="d-flex align-items-start pe-xl-3">
                  <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                  <img src="assets/features_img/features_icons/security_key.svg" alt="Icon"/>
                  </div>
                  <div className="ps-4 ps-sm-3 ps-md-4">
                    <h3 className="h5 pb-1 mb-2">Physical Security keys</h3>
                    <p className="mb-0">Enhance security with Orbix hardware-based Multi-factor authentication for unparalleled access protection.</p>
                  </div>
                </div>
              </div>

           
              <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5 justify-content-center d-flex">
              <img src="assets/features_img/physical_security_keys.png" className="rounded-3 mt-n5 key-img " alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>


        </>
    )

}

export default DataControlHubSection;