import React from "react";

const AIDataIntegritySection =() =>{
    return(
        <>
        <section className="container px-3  pt-3  mb-3">
        <div className="row  align-items-lg-center justify-content-center p-5">
          <div className="col-lg-5 col-md-7 mt-4 pt-md-0 pt-2  d-flex justify-content-sm-center order-2 order-md-1">
              <div className="pe-lg-5">
                <img src="assets/features_img/ai_data_integrity.png" class="rounded-3" alt="Image"/>
              </div>
            </div>
            <div className="col-lg-6 col-md-5  text-center text-md-start order-1 order-md-2">
              <h2 className="h2 mb-sm-4">AI Data Integrity Monitoring</h2>
                  <p className=" pb-2 pb-md-3 mb-3 ">
                  Orbix's AI Data Integrity Monitor: Your tireless, eagle-eyed guardian. This sophisticated system continuously scans incoming inspection data, preventing integrity issues before they impact your operations. It catches duplicate girth welds despite disguise attempts and detects misplaced shots by analyzing each weld's unique 'fingerprint'. With Orbix, you're not just collecting data - you're ensuring its absolute integrity.
                  </p>   
            </div>
        </div>
      </section>

      </>
    )

}

export default  AIDataIntegritySection;