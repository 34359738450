import React from 'react'

const OrbixDiagram =() => {
  return (
    <>
  <section class="container border-bottom  text-center mt-3 pb-5 mb-3 mb-md-4 mb-lg-5">
  <h2 class="h1 pt-5 mb-4">Modernized NDE Workflows</h2>
  <div class="row justify-content-center mb-md-2 mb-lg-5">
    <div class="col-lg-10 col-md-8">
      <p class="fs-lg text-muted mb-lg-0">Orbix is a cloud platform designed to modernize NDE  by enabling digital NDE workflows and impactful AI functionality.</p>
    </div>
  </div>
  <div class="position-relative rounded-3 overflow-hidden mb-5 pb-3">
    
    <span class="position-absolute top-0 start-0 w-100 h-100"></span>
    <img src="assets/img/xpose/MissionDiagram.png " alt="Cover"/>
  </div>
</section>


    </>
 
  )
}

export default OrbixDiagram;
