import React from "react";

const UserManagementHubSection =() =>{
    return(
        <>
        <section className="container px-3 pt-3 mb-3">
        <div className="row  align-items-lg-center justify-content-center p-5">
          
          <div className="col-lg-6 col-md-5 text-center text-md-start pe-lg-5">
            <h2 className="h2 mb-sm-4">User Management and Certification Tracking</h2>
                <p className=" pb-2 pb-md-3 mb-3">
                Orbix simplifies NDE team management. Assign roles precisely, track certifications automatically, and manage project access effortlessly. Stay ahead with timely expiration alerts. Streamline your workforce management.
                </p>   
          </div>
          <div className="col-md-5 bg-secondary rounded-3 ">
            <div className=" p-5">
              <img src="assets/features_img/user_management_hub.png" class="rounded-3" alt="Image"/>
            </div>
          </div>
        </div>
      </section>

      </>
    )

}

export default UserManagementHubSection;