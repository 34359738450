import React from "react";
import './advancedcloudviewer.component.css'

const AdvancedCloudViewerSection =() =>{
    return(
        <>
           <section className="container px-3 text-start  mb-3 ">
          <h2 className="h1 pt-5 mb-4">Advanced Cloud Viewer</h2>
          <div className="row align-items-start mb-md-2 mb-lg-5">
            <div className="col-md-11 col-lg-10 col-12">
              <p className="fs-lg mb-lg-0">Orbix's Advanced Cloud Viewer sets a new standard for radiographic testing analysis. Our viewer combines universal compatibility with cutting-edge tools, enabling faster, more accurate, and truly collaborative inspections. Experience the future of RT data interpretation and reporting.</p>
            </div>
          </div>

          <div className="row align-items-center">
          <div className="col-lg-7">
          <div className="row row-cols-1 row-cols-md-2 align-items-stretch">
            <div className="col h-100">
              <div className="card card-hover bg-secondary border-0 mb-4 h-100">
                <div className="card-body card-body-custom-1 d-flex flex-column align-items-start">
                  <div className="ps-4">
                    <h3 className="h5 pb-2 mb-1">Universal Compatibility</h3>
                    <p className="pb-2 mb-1">Seamlessly work with radiographic data from all major providers. Our viewer speaks every language in the RT world, ensuring you're never limited by file formats or data sources.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col h-100">
              <div className="card card-hover bg-secondary border-0 mb-4 h-100">
                <div className="card-body d-flex flex-column align-items-start">
                  <div className="ps-4">
                    <h3 className="h5 pb-2 mb-1">Intelligent Image SRb Determination</h3>
                    <p className="pb-2 mb-1">Take the guesswork out of image quality assessment. Quickly, easily, and accurately determine basic spatial resolution, regardless of IQI wire orientation.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col h-100">
              <div className="card card-hover bg-secondary border-0 mb-4 h-100">
                <div className="card-body card-body-custom-2 d-flex flex-column align-items-start">
                  <div className="ps-4">
                    <h3 className="h5 pb-2 mb-1">Rapid and Reliable Performance</h3>
                    <p className="pb-2 mb-1">Experience lightning-fast load times and rock-solid stability, even with large, complex RT datasets. Our cloud viewer keeps pace with your workflow, ensuring you spend less time waiting and more time analyzing.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col h-100">
              <div className="card card-hover bg-secondary border-0 mb-4 h-100">
                <div className="card-body d-flex flex-column align-items-start">
                  <div className="ps-4">
                    <h3 className="h5 pb-2 mb-1">Collaborative Reporting and Analysis</h3>
                    <p className="pb-2 mb-1">Transform your RT reporting process with our real-time collaborative tools. Create, edit, and share structured reports across multiple images as easily as you would in a shared document. It's teamwork, reimagined for the RT world.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col h-100">
              <div className="card card-hover bg-secondary border-0 mb-4 h-100">
                <div className="card-body d-flex flex-column align-items-start">
                  <div className="ps-4">
                    <h3 className="h5 pb-2 mb-1">Lossless Precision Filter</h3>
                    <p className="pb-2 mb-1">Enhance your RT images without compromising data integrity. Our unique filter allows you to refine images for different inspection needs or equipment types, revealing critical details while preserving all visual information.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col h-100">
              <div className="card card-hover bg-secondary border-0 mb-4 h-100">
                <div className="card-body d-flex flex-column align-items-start">
                  <div className="ps-4">
                    <h3 className="h5 pb-2 mb-1">Cross-Device Accessibility</h3>
                    <p className="pb-2 mb-1">Access your RT data anytime, anywhere. Our cloud viewer performs seamlessly across all devices - from powerful workstations to tablets and smartphones. Your office is wherever you are, with the full power of Orbix at your fingertips.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          </div>
          <div className="col-lg-5 mt-4 pt-md-0 pt-2 d-flex justify-content-sm-center ">
            <div className="pe-lg-5 ">
              <img src="assets/features_img/advanced_cloud_viewer.png" class="rounded-3" alt="Image"/>
            </div>
          </div>
        </div>
    
          
        </section>

        </>
    )

}

export default  AdvancedCloudViewerSection;