import React, { useState } from "react";
import './partnerssection.component.css'
import { Link } from "react-router-dom"; 



const PartnersSection =()=>{
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const isTechCorrVisible = "TechCorr".toLowerCase().includes(searchQuery);
  const isPrimeNDTVisible = "Prime NDT Services, Inc.".toLowerCase().includes(searchQuery);
    
    return(
        <>

        <div className="background">
                {/* <!-- Breadcrumb --> */}
      <nav className="container py-4 mb-2 my-lg-3" aria-label="breadcrumb">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">Partners</li>
        </ol>
      </nav>

      {/* <!-- Search input --> */}
      <div className="container">
        <div className="col-lg-3 col-sm-6">
          <div className="input-group">
            <input type="text" 
            class="form-control pe-5 rounded" 
            placeholder="Search service partners..."
            value={searchQuery}
            onChange={handleSearchChange}
            />
            <i className="bx bx-search position-absolute top-50 end-0 translate-middle-y me-3 zindex-5 fs-lg"></i>
          </div>
        </div>
      </div>
      

      {/* List of Partners */}

      <section className="container mb-5 pt-5 pb-lg-5">
        <div className="pb-3">
          
          {/* <!-- Partner 1--> */}
          {isTechCorrVisible && (
          <article className="card border-0 shadow-sm overflow-hidden mb-4">
            <div className="row g-0">
            <div className="col-sm-4 position-relative bg-repeat-0 d-flex justify-content-center align-items-center with-border">
              <a href="https://www.techcorr.com/" className="position-absolute top-0 start-0 w-100 h-100" target="_blank" rel="noopener noreferrer" aria-label="Read more"></a>
              <img src="assets/custom_img/techcorr-logo.png" alt="TechCorr" className="img-fluid p-1" />
            </div>
              <div className="col-sm-8">
                <div className="card-body">
                 
                  <h3 className="h4 text-uppercase">
                    <a href="https://www.techcorr.com/" target="_blank" rel="noopener noreferrer">TechCorr</a>
                  </h3>
                  <p>TechCorr, established in 1999 in Pasadena, Texas, is a global leader in advanced non-destructive testing (NDT) and inspection services. With over 20 years of experience and 20 international locations, the company blends cutting-edge technology with highly skilled and certified professionals. This approach not only ensures exceptional safety and efficiency but also places TechCorr at the forefront of addressing and resolving complex industry challenges.</p>
                  <hr className="my-4" />
                   {/* <!-- Company Info--> */}
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center text-muted custom-margin">
                      <div className="d-flex align-items-center me-3 custom-margin">
                      <i className="bx bx-map fs-xl me-1"></i>
                        <span className="fs-sm">Houston , USA</span>
                      </div>
                      <div className="d-flex align-items-center me-3 custom-margin">
                      <i className="bx bx-envelope fs-xl me-1"></i>
                        <span className="fs-sm">contact@techcorr.com</span>
                      </div>
                      <div className="d-flex align-items-center">
                      <i className="bx bx-phone-call fs-xl me-1 custom-margin "></i>
                        <span className="fs-sm">+1 (713) 524-2768</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          )}

          {/* <!-- Partner 2--> */}
          {isPrimeNDTVisible && (
          <article className="card border-0 shadow-sm overflow-hidden mb-4">
            <div className="row g-0">
            <div className="col-sm-4 position-relative bg-repeat-0 d-flex justify-content-center align-items-center with-border">
              <a href="https://www.rae.com/primendt" className="position-absolute top-0 start-0 w-100 h-100" target="_blank" rel="noopener noreferrer" aria-label="Read more"></a>
              <img src="assets/custom_img/primendt-logo.png" alt="Prime NDT Services, Inc." className="img-fluid p-1" />
            </div>
              
              <div className="col-sm-8">
                <div className="card-body">
                 
                  <h3 className="h4 text-uppercase">
                    <a href="https://www.rae.com/primendt" target="_blank" rel="noopener noreferrer">Prime NDT Services, Inc.</a>
                  </h3>
                  <p>Established with a dedication to performing the highest level of quality work in nondestructive testing (NDT) services, Prime NDT excels across a variety of new construction and integrity assessment projects in the energy and industrial industries. The company is committed to delivering unparalleled quality in NDT services, ensuring safety and efficiency in every project undertaken.</p>
                  <hr className="my-4" />
                   {/* <!-- Company Info--> */}
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center text-muted custom-margin">
                      <div className="d-flex align-items-center custom-margin me-3">
                      <i className="bx bx-map fs-xl me-1"></i>
                        <span className="fs-sm">OHIO , USA</span>
                      </div>
                      <div className="d-flex align-items-center custom-margin me-3">
                      <i className="bx bx-envelope fs-xl me-1"></i>
                        <span className="fs-sm">info@primendt.com</span>
                      </div>
                      <div className="d-flex align-items-center">
                      <i className="bx bx-phone-call fs-xl custom-margin  me-1"></i>
                        <span className="fs-sm">+1 (330) 878-4122</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          )}
        </div>

        {/* <!-- Load more btn --> */}
        {/* <a href="/" className="btn btn-lg btn-outline-primary w-100">
          <i className="bx bx-down-arrow-alt me-2 lh-1 lead"></i>
          Show more
        </a> */}
      </section>

        </div>



        </>
    )
};

export default PartnersSection