import React from 'react';
import HeaderSection from '../../components/HeaderSection/headersection.component';
import FooterSection from '../../components/FooterSection/footersection.component';
import { Fragment } from 'react';

function PrivacyPolicy() {
  return (
    <Fragment>
      <main className="page-wrapper">
        {/* Hero  */}
        <HeaderSection />
        
        {/* Privacy Policy */}
        <section className="position-relative overflow-hidden mt-5  ">
          <div className="container">
            <div className="row flex-lg-nowrap ">
              <div className="col-lg-6 col-xl-5">
                <h1 className="display-6 pt-5 pb-2 pb-lg-3 mt-2 mt-lg-5 text-uppercase">Privacy Policy </h1>
                <strong className="d-block mb-3 text-muted">Last updated June 13, 2024 - V.1</strong>
              </div>
            </div>
          </div>
         
        </section>

        {/*Privacy Policy PDF */}
        <section className="container mb-5 pt-4 pb-2 py-mg-4">
          <div style={{ width: '100%', height: '1000px' }}>
            <iframe
              title="PDF Viewer 1"
              src={process.env.PUBLIC_URL + "/assets/pdf/PrivacyPolicyofORBIXNDE.pdf"}
              width="100%"
              height="1000px"
            ></iframe>
          </div>
        </section>
      </main>
      
      {/* Footer  */}
      <FooterSection />
    </Fragment>
  );
}

export default PrivacyPolicy;
