import React from 'react';
import HeaderSection from '../../components/HeaderSection/headersection.component';
import FeaturesHeroSection from '../../components/FeaturesHeroSection/featuresherosection';
import DigitalAuditWorkflowsSection from '../../components/Digital Audit Workflows/digitalauditworkflows.component';
import RealtimeCommunicationSection from '../../components/RealtimeCommunicationSection/realtimecommunicationsection.component';
import IntelligentDataManagementSection from '../../components/IntelligentDataManagementSection/intelligentdatamanagementsection';
import AdvancedCloudViewerSection from '../../components/AdvancedCloudViewerSection/advancedcloudviewer.component';
import AIDataIntegritySection from '../../components/AIDataIntegritySection/aIdataintegrity.componenet';
import UserManagementHubSection from '../../components/UserManagementHubSection/usermanagementhub.component';
import DataControlHubSection from '../../components/DataControlHubSection/DataControlHubSection';
import ScalableProjectHubSection from '../../components/ScalableProjectHubSection/scalableprojecthub';
import OrbixContact from '../../components/ContactSection/contact.component';
import FooterSection from '../../components/FooterSection/footersection.component';
import { Fragment } from "react";


function Features() {
  return (
    <Fragment>
    {/* main */}
    <main className="page-wrapper">
    {/* Header  */}
    <HeaderSection />

    {/* Hero  */}
    <FeaturesHeroSection/>

    
    {/* Digital Audit Workflows Section  */}

   <DigitalAuditWorkflowsSection/>


    {/*Realtime Communication Section  */}

    <RealtimeCommunicationSection/>


     {/*Intelligent Data Management Section  */}
    <IntelligentDataManagementSection/>

     {/*Advanced Cloud Viewer Section  */}
     <AdvancedCloudViewerSection/>

    {/*AI Data Integrity Section  */}
     <AIDataIntegritySection/>

    {/*User Managemen tHub Section  */}
     <UserManagementHubSection/>

    {/*Data Control Hub Section */}
     <DataControlHubSection/>

    {/*Scalable Project Hub Section  */}
     <ScalableProjectHubSection/>



    {/* Contact -1 */}
    <OrbixContact/>
     
    </main>
    {/* Footer  */}
    <FooterSection />

    </Fragment>
  )
}

export default Features