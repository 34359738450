import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './newsinnersection.component.css';


const titleToSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');
};

const NewsInnerSection = () => {
  const [post, setPost] = useState(null);
  const { postSlug } = useParams();

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(`https://public-api.wordpress.com/rest/v1.1/sites/orbix0.wordpress.com/posts`);
      const data = await response.json();
      if (data.posts) {
        // Filter the posts client-side to find the one that matches the slug
        const matchingPost = data.posts.find(post => titleToSlug(post.title) === postSlug);
        if (matchingPost) {
          setPost(matchingPost);
        } else {
          console.log("No post found for the given slug.");
          setPost(null);
        }
      }
    };
  
    fetchPosts();
  }, [postSlug]);
  

    
    return(
        <>

          <div className="col-lg-8 mx-auto custom-font">
            {/* <!-- Breadcrumb --> */}

            <nav aria-label="breadcrumb" className="container mt-lg-5 pt-5 cmt-6">
              <ol className="breadcrumb mb-0  pb-2">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/news">News</a>
                </li>
                {post ? (
                <li className="breadcrumb-item active" aria-current="page">{post.title}</li>
                ):(<p></p>)}
              </ol>
            </nav>
          


            <section className="container mt-4 pt-lg-2">
            {post ? (
                <>
                <h1 className="pb-3" style={{maxWidth: "970px"}}>{post.title}</h1>
                <div className="d-flex flex-md-row flex-column align-items-md-center justify-content-md-between mb-3">
                  <div className="d-flex align-items-center flex-wrap text-muted mb-md-0 mb-4">
                    {/* <div className="fs-xs border-end pe-3 me-3 mb-2">
                      <span className="badge bg-faded-primary text-primary fs-base">Technology</span>
                    </div> */}
                    <div className="fs-md border-end pe-3 me-3 mb-2">{new Date(post.date).toLocaleDateString()}</div>
                    <div className="d-flex mb-2">
                      {/* <div className="d-flex align-items-center me-3">
                        <i className="bx bx-like fs-base me-1"></i>
                        <span className="fs-sm">8</span>
                      </div> */}
                      {/* Share icons section */}
                      <div className="d-flex align-items-center me-3">
                        {/* <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer" className="btn btn-icon btn-secondary btn-twitter me-2 mb-2">
                          <i className="bx bxl-facebook fs-base me-1"></i>
                        </a> */}
                        <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer"  className="btn btn-icon btn-secondary btn-twitter me-2 mb-2">
                          <i className="bx bxl-twitter fs-base me-1"></i>
                        </a>
                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`} target="_blank" rel="noopener noreferrer" className="btn btn-icon btn-secondary btn-linkedin me-2 mb-2">
                          <i className="bx bxl-linkedin fs-base me-1"></i>
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                </>
                ) : (
                  <p></p>
              )}
            </section>

            <section className="container mb-5 pb-2 py-mg-4">
              <div className="row gy-4">
              <div className="col-lg">
                  {post ? (
                    <>
                      <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    </>
                  ) : (
                    <p>Loading post...</p>
                  )}
                </div>
              </div>
            </section>
          </div>
      </>
    
    )
};

export default NewsInnerSection