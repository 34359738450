import React from "react";

const ScalableProjectHubSection =() =>{
    return(
        <>
<section className="container px-3 pt-3 mb-5">
  <div className="row align-items-lg-center justify-content-center p-5">
    <div className="col-lg-5 col-md-7 mt-4 pt-md-0 pt-2 d-flex justify-content-sm-center order-2 order-md-1">
      <div className="pe-lg-5">
        <img src="assets/features_img/scalable_multi_project.png" className="rounded-3" alt="Image" />
      </div>
    </div>
    <div className="col-lg-6 col-md-5 text-center text-md-start order-1 order-md-2">
      <h2 className="h2 mb-sm-4">Scalable Multi-Project Management</h2>
      <p className="pb-2 pb-md-3 mb-3 ">
        Seamless Multi-Project Management with Orbix redefines NDE scalability. Oversee one project or one thousand, across any number of locations, all from one powerful platform. Orbix adapts to your growth, ensuring your NDE processes remain efficient and consistent.
      </p>
    </div>
  </div>
</section>




      </>
    )

}

export default   ScalableProjectHubSection;